.App::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(3px); /* Ajusta el nivel de desenfoque */
      }
      .container {
        position: relative;
        z-index: 2; /* Asegúrate de que el contenido esté sobre el desenfoque */
        color: white; /* Ajusta el color del texto según sea necesario */
      }

/* src/components/CalendarComponent.css */
.calendar-tile-content {
    position: relative;
    text-align: center;
  }
  
  .calendar-tile-content img {
    max-width: 100%;
    max-height: 100px; /* Ajusta la altura según sea necesario */
    border-radius: 50%;
  }
  

  
  .calendar-tile-content {
    position: relative;
    text-align: center;
  }
  
  .calendar {
  border-radius: 8px; /* Redondea los bordes (opcional) */
  box-shadow: 
    2px 2px 5px rgba(0, 0, 0, 0.3), /* Sombra secundaria para el efecto de profundidad */
    -2px -2px 5px rgba(255, 255, 255, 0.7);
    background-color: #FFFFFFCA;
    width: 100%; /* Hace que el calendario ocupe todo el ancho del contenedor */
    /*max-width: 500px; /* Ajusta el ancho máximo según tus necesidades */
  }

  
ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: justify;
}
li {
    margin-bottom: 10px;
}

body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    /*padding: 20px;*/
    background-color: #f4f4f4;
}


.react-calendar__navigation button {
    font-size: 1.5em; /* Aumenta el tamaño de los botones de navegación */
    text-transform: uppercase;

  }
  
  .react-calendar__month-view__days {
    font-size: 1.2em; /* Aumenta el tamaño de los días */
  }
  
  .react-calendar__month-view__weekdays {
    font-size: 0.9em; /* Aumenta el tamaño de los nombres de los días de la semana */
  }
  
  .react-calendar__month-view__header {
    display: flex;
    justify-content: center;
    font-size: 1.7em; /* Aumenta el tamaño de la cabecera del mes */
  }
  
  .col-12{
    margin-bottom: 10px;
  }
  .row{
    padding-top: 10px;
    margin-top: 10px;
  }
  .calendar-day-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    color: white; /* Cambia el color del texto según el fondo de la imagen */
    font-size: 1.2em; /* Ajusta el tamaño del número */
    font-weight: bold; /* Ajusta el grosor del texto si es necesario */
    text-shadow: 
    1px 1px 0 #000,  
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000;
  }

.react-calendar__month-view__days abbr{
    display: none;
}

.transparent{
    opacity: 0.5;
}

h1, p,li{
    color: white !important; /* Cambia el color del texto según el fondo de la imagen */
    font-size: 1em; /* Ajusta el tamaño del número */
    font-weight: bold; /* Ajusta el grosor del texto si es necesario */
    text-shadow: 
    1px 1px 0 #000,  
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000;
}

.list-container{    
    background-color: #00000080;
}

.calendar-container{
    padding: 20px;
}

.name-calendar{
    font-size: 0.70rem;
}

li > strong {
    font-size: 1.2rem;
}

.red-image {
    filter: sepia(100%) saturate(1000%) hue-rotate(-50deg);
  }

  .yellow-image {
    filter: sepia(100%) saturate(1000%) hue-rotate(60deg);
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    10% {
        opacity: 1;
      }
    50% {
      opacity: 0;
    }
    90% {
        opacity: 1;
      }
    100% {
      opacity: 1;
    }
  }
  
  /* Aplica la animación a la clase */
  .today-effect {
    animation: blink 1.2s linear infinite; /* Ajusta la duración y repetición de la animación */
  }

  .react-calendar__tile--now {
    background: none;
  }